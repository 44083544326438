var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Button Name","label-for":"btn"}},[_c('validation-provider',{attrs:{"name":"btn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn","state":errors.length > 0 ? false : null,"name":"btn","placeholder":"Enter Your Button Name"},model:{value:(_vm.form.btn),callback:function ($$v) {_vm.$set(_vm.form, "btn", $$v)},expression:"form.btn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Button Link","label-for":"btn_link"}},[_c('validation-provider',{attrs:{"name":"btn_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn_link","state":errors.length > 0 ? false : null,"name":"btn_link","placeholder":"Enter Your Button Link"},model:{value:(_vm.form.btn_link),callback:function ($$v) {_vm.$set(_vm.form, "btn_link", $$v)},expression:"form.btn_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{attrs:{"label":"Job Placement Department Section Title","label-for":"placement_department_title"}},[_c('validation-provider',{attrs:{"name":"placement_department_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"placement_department_title","state":errors.length > 0 ? false : null,"name":"placement_department_title","placeholder":"Enter Your Job Placement Department Section Title"},model:{value:(_vm.form.placement_department_title),callback:function ($$v) {_vm.$set(_vm.form, "placement_department_title", $$v)},expression:"form.placement_department_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Job Placement Success Story Section Title","label-for":"placement_success_story_title"}},[_c('validation-provider',{attrs:{"name":"placement_success_story_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"placement_success_story_title","state":errors.length > 0 ? false : null,"name":"placement_success_story_title","placeholder":"Enter Your Job Placement Success Story Section Title"},model:{value:(_vm.form.placement_success_story_title),callback:function ($$v) {_vm.$set(_vm.form, "placement_success_story_title", $$v)},expression:"form.placement_success_story_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"border rounded p-2 mb-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Video Setting")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.video_thumbnail,"height":"174","width":"310"}})],1),_c('b-media-body',[_c('b-form-group',{attrs:{"label":"Video Thumbnail","label-for":"video_thumbnail"}},[_c('b-form-file',{attrs:{"id":"video_thumbnail","accept":".jpg, .png, .gif","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.loadImage($event)}}})],1),_c('b-form-group',{attrs:{"label":"Video ID","label-for":"video_id"}},[_c('validation-provider',{attrs:{"name":"video_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"video_id","state":errors.length > 0 ? false : null,"name":"video_id","placeholder":"Enter Your Video ID"},model:{value:(_vm.form.video_id),callback:function ($$v) {_vm.$set(_vm.form, "video_id", $$v)},expression:"form.video_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)]),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Job Placement Partner Section Title","label-for":"placement_partner_title"}},[_c('validation-provider',{attrs:{"name":"placement_partner_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"placement_partner_title","state":errors.length > 0 ? false : null,"name":"placement_partner_title","placeholder":"Enter Your Job Placement Partner Section Title"},model:{value:(_vm.form.placement_partner_title),callback:function ($$v) {_vm.$set(_vm.form, "placement_partner_title", $$v)},expression:"form.placement_partner_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Select Career Placement Partner","label-for":"partner_id"}},[_c('validation-provider',{attrs:{"name":"partner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"partner_id","placeholder":"Select  Career Placement Partner","multiple":"","options":_vm.placement_partners,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.form.partner_id),callback:function ($$v) {_vm.$set(_vm.form, "partner_id", $$v)},expression:"form.partner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-7"},[_c('b-form-group',{attrs:{"label":"CV Send Section Title","label-for":"cv_send_section_title"}},[_c('validation-provider',{attrs:{"name":"cv_send_section_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cv_send_section_title","state":errors.length > 0 ? false : null,"name":"cv_send_section_title","placeholder":"Enter Your CV Send Section Title"},model:{value:(_vm.form.cv_send_section_title),callback:function ($$v) {_vm.$set(_vm.form, "cv_send_section_title", $$v)},expression:"form.cv_send_section_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CV Send Section Description","label-for":"cv_send_section_description"}},[_c('validation-provider',{attrs:{"name":"cv_send_section_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cv_send_section_description","state":errors.length > 0 ? false : null,"name":"cv_send_section_description","placeholder":"Enter Your CV Send Section Description","rows":"4"},model:{value:(_vm.form.cv_send_section_description),callback:function ($$v) {_vm.$set(_vm.form, "cv_send_section_description", $$v)},expression:"form.cv_send_section_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CV Send Button Name","label-for":"cv_btn"}},[_c('validation-provider',{attrs:{"name":"cv_btn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cv_btn","state":errors.length > 0 ? false : null,"name":"cv_btn","placeholder":"Enter Your CV Send Button Name"},model:{value:(_vm.form.cv_btn),callback:function ($$v) {_vm.$set(_vm.form, "cv_btn", $$v)},expression:"form.cv_btn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CV Send Button Link","label-for":"cv_btn_link"}},[_c('validation-provider',{attrs:{"name":"cv_btn_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cv_btn_link","state":errors.length > 0 ? false : null,"name":"cv_btn_link","placeholder":"Enter Your CV Send Button Link"},model:{value:(_vm.form.cv_btn_link),callback:function ($$v) {_vm.$set(_vm.form, "cv_btn_link", $$v)},expression:"form.cv_btn_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1),_c('div',{staticClass:"col-md-5"},[_c('b-form-group',{attrs:{"label":"Meta Title","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","state":errors.length > 0 ? false : null,"name":"meta_title","placeholder":"Enter Your Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meta Description","label-for":"meta_description"}},[_c('validation-provider',{attrs:{"name":"meta_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"name":"meta_description","placeholder":"Enter Your Meta Description","rows":"7"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }