<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <!-- <div class="col-md-12">
            <b-form-group label="Select Marketplace" label-for="marketplace_id">
              <validation-provider
                #default="{ errors }"
                name="marketplace_id"
                rules="required"
              >
                <v-select
                  id="marketplace_id"
                  v-model="form.marketplace_id"
                  placeholder="Select Marketplace"
                  multiple
                  :options="marketplaces"
                  label="name"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div> -->
          <div class="col-md-8">
            <b-form-group label="Title" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                  name="title"
                  placeholder="Enter Your Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  placeholder="Enter Your Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="Button Name" label-for="btn">
                  <validation-provider
                    #default="{ errors }"
                    name="btn"
                    rules="required"
                  >
                    <b-form-input
                      id="btn"
                      v-model="form.btn"
                      :state="errors.length > 0 ? false : null"
                      name="btn"
                      placeholder="Enter Your Button Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Button Link" label-for="btn_link">
                  <validation-provider
                    #default="{ errors }"
                    name="btn_link"
                    rules="required"
                  >
                    <b-form-input
                      id="btn_link"
                      v-model="form.btn_link"
                      :state="errors.length > 0 ? false : null"
                      name="btn_link"
                      placeholder="Enter Your Button Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <b-form-group
              label="Job Placement Department Section Title"
              label-for="placement_department_title"
            >
              <validation-provider
                #default="{ errors }"
                name="placement_department_title"
                rules="required"
              >
                <b-form-input
                  id="placement_department_title"
                  v-model="form.placement_department_title"
                  :state="errors.length > 0 ? false : null"
                  name="placement_department_title"
                  placeholder="Enter Your Job Placement Department Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Job Placement Success Story Section Title"
              label-for="placement_success_story_title"
            >
              <validation-provider
                #default="{ errors }"
                name="placement_success_story_title"
                rules="required"
              >
                <b-form-input
                  id="placement_success_story_title"
                  v-model="form.placement_success_story_title"
                  :state="errors.length > 0 ? false : null"
                  name="placement_success_story_title"
                  placeholder="Enter Your Job Placement Success Story Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <div class="border rounded p-2 mb-2">
              <h4 class="mb-1">Video Setting</h4>

              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    :src="video_thumbnail"
                    height="174"
                    width="310"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-form-group
                    label="Video Thumbnail"
                    label-for="video_thumbnail"
                  >
                    <b-form-file
                      id="video_thumbnail"
                      accept=".jpg, .png, .gif"
                      @change="loadImage($event)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group>
                  <!-- email -->
                  <b-form-group label="Video ID" label-for="video_id">
                    <validation-provider
                      #default="{ errors }"
                      name="video_id"
                      rules="required"
                    >
                      <b-form-input
                        id="video_id"
                        v-model="form.video_id"
                        :state="errors.length > 0 ? false : null"
                        name="video_id"
                        placeholder="Enter Your Video ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="Job Placement Partner Section Title"
              label-for="placement_partner_title"
            >
              <validation-provider
                #default="{ errors }"
                name="placement_partner_title"
                rules="required"
              >
                <b-form-input
                  id="placement_partner_title"
                  v-model="form.placement_partner_title"
                  :state="errors.length > 0 ? false : null"
                  name="placement_partner_title"
                  placeholder="Enter Your Job Placement Partner Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="Select Career Placement Partner"
              label-for="partner_id"
            >
              <validation-provider
                #default="{ errors }"
                name="partner_id"
                rules="required"
              >
                <v-select
                  id="partner_id"
                  v-model="form.partner_id"
                  placeholder="Select  Career Placement Partner"
                  multiple
                  :options="placement_partners"
                  label="name"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-7">
            <b-form-group
              label="CV Send Section Title"
              label-for="cv_send_section_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cv_send_section_title"
                rules="required"
              >
                <b-form-input
                  id="cv_send_section_title"
                  v-model="form.cv_send_section_title"
                  :state="errors.length > 0 ? false : null"
                  name="cv_send_section_title"
                  placeholder="Enter Your CV Send Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CV Send Section Description"
              label-for="cv_send_section_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cv_send_section_description"
                rules="required"
              >
                <b-form-textarea
                  id="cv_send_section_description"
                  v-model="form.cv_send_section_description"
                  :state="errors.length > 0 ? false : null"
                  name="cv_send_section_description"
                  placeholder="Enter Your CV Send Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="CV Send Button Name" label-for="cv_btn">
                  <validation-provider
                    #default="{ errors }"
                    name="cv_btn"
                    rules="required"
                  >
                    <b-form-input
                      id="cv_btn"
                      v-model="form.cv_btn"
                      :state="errors.length > 0 ? false : null"
                      name="cv_btn"
                      placeholder="Enter Your CV Send Button Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="CV Send Button Link"
                  label-for="cv_btn_link"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cv_btn_link"
                    rules="required"
                  >
                    <b-form-input
                      id="cv_btn_link"
                      v-model="form.cv_btn_link"
                      :state="errors.length > 0 ? false : null"
                      name="cv_btn_link"
                      placeholder="Enter Your CV Send Button Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  props: ["marketplaces"],
  data() {
    return {
      form: {
        title: "",
        description: "",
        placement_department_title: "",
        btn: "",
        btn_link: "",
        placement_success_story_title: "",
        placement_partner_title: "",
        partner_id: [],
        video_id: "",
        video_thumbnail: null,
        cv_send_section_title: "",
        cv_send_section_description: "",
        cv_btn: "",
        cv_btn_link: "",
        meta_title: "",
        meta_description: "",
      },
      video_thumbnail: null,
      required,
      email,
    };
  },
  mounted() {
    this.getPage();
    this.$store.dispatch("getCareerPlacementPartner");
  },
  methods: {
    async getPage() {
      const placement = await this.callApi(
        "get",
        "/app/job/placement/page/show"
      );
      if (placement.status == 200) {
        this.form.title = placement.data.placement.title;
        this.form.description = placement.data.placement.description;
        this.form.placement_department_title =
          placement.data.placement.placement_department_title;
        this.form.btn = placement.data.placement.btn;
        this.form.btn_link = placement.data.placement.btn_link;
        this.form.placement_success_story_title =
          placement.data.placement.placement_success_story_title;
        this.video_thumbnail =
          this.$store.state.base_url +
          (placement.data.placement.video_thumbnail
            ? placement.data.placement.video_thumbnail
            : "images/job_placement_page/default.jpg");
        this.form.video_id = placement.data.placement.video_id;
        this.form.placement_partner_title =
          placement.data.placement.placement_partner_title;
        this.form.partner_id = [];
        placement.data.placement.placement_partners.forEach((placement) => {
          this.form.partner_id.push(placement.id);
        });
        this.form.cv_send_section_title =
          placement.data.placement.cv_send_section_title;
        this.form.cv_send_section_description =
          placement.data.placement.cv_send_section_description;
        this.form.cv_btn = placement.data.placement.cv_btn;
        this.form.cv_btn_link = placement.data.placement.cv_btn_link;
        this.form.meta_title = placement.data.placement.meta_title;
        this.form.meta_description = placement.data.placement.meta_description;
      }
    },

    loadImage(e) {
      this.form.video_thumbnail = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.video_thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.video_thumbnail) {
        fd.append(
          "video_thumbnail",
          this.form.video_thumbnail,
          this.form.video_thumbnail.name
        );
      }

      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append(
        "placement_department_title",
        this.form.placement_department_title
      );
      fd.append("btn", this.form.btn);
      fd.append("btn_link", this.form.btn_link);
      fd.append(
        "placement_success_story_title",
        this.form.placement_success_story_title
      );
      fd.append("placement_partner_title", this.form.placement_partner_title);
      for (let i = 0; i < this.form.partner_id.length; i++) {
        let partner_id = this.form.partner_id[i];
        fd.append("partner_id[" + i + "]", partner_id);
      }
      fd.append("video_id", this.form.video_id);
      fd.append("cv_send_section_title", this.form.cv_send_section_title);
      fd.append(
        "cv_send_section_description",
        this.form.cv_send_section_description
      );
      fd.append("cv_btn", this.form.cv_btn);
      fd.append("cv_btn_link", this.form.cv_btn_link);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      axios
        .post("/app/job/placement/page/update", fd)
        .then((res) => {
          this.getPage();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.partner_id)
              this.e(e.response.data.errors.partner_id[0]);
            if (e.response.data.errors.video_id)
              this.e(e.response.data.errors.video_id[0]);
            if (e.response.data.errors.video_thumbnail)
              this.e(e.response.data.errors.video_thumbnail[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  computed: {
    placement_partners() {
      return this.$store.getters.getCareerPlacementPartner;
    },
  },
  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
